import {draw, drawHistory, drawVessel, populateData, showAlert, cancelAlert} from "./map";
import {initializeApp} from "firebase/app";
import {getAuth, signInWithPopup, GoogleAuthProvider, signOut, User} from "firebase/auth";
import {RTCClient} from "./RTCClient";
import 'firebaseui/dist/firebaseui.css'
import {saltyrtcClient} from "@ark-us/saltyrtc-transport";

// Initialize Firebase (see: https://firebase.google.com/docs/web/learn-more#config-object)
initializeApp({
    apiKey: "AIzaSyCFchia19V9QblDML0h1QHtEzacWc2UM3Y",
    authDomain: "anchor-alert-web-view.firebaseapp.com",
    projectId: "anchor-alert-web-view",
    storageBucket: "anchor-alert-web-view.appspot.com",
    messagingSenderId: "601748296570",
    appId: "1:601748296570:web:2d3e674d71b4666a42bcc3"
});

const auth = getAuth();
let rtcClient: RTCClient;
const keyServer = "https://heimdall.slimjimsoftware.co.uk";
// "http://localhost:5001"

function connectToPeer(user: User) {
    if (user && user.uid) {
        hide('login-button');
        show('logout');
        show(['loading-container', 'loadingState']);

        // generate new pub / priv key pair
        const keyStore = new saltyrtcClient.KeyStore();

        show('request-connection');
        const connectionRequestBtn = document.getElementById("request-connection");
        connectionRequestBtn.addEventListener("click", () => {
            connectionRequestBtn.innerHTML = "Connecting...";
            document.querySelector("#loadingState").innerHTML = "Requesting connection...";
            let timeoutId = setTimeout(() => {
                document.querySelector("#loadingState").innerHTML = "Connection request failed, check the other device is running and try again.";
                connectionRequestBtn.innerHTML = "Request connection";
                rtcClient.stop();
            }, 15000); // 15 seconds should be enough
            fetch(`${keyServer}/getToken?userId=${user.uid}&pubKey=${keyStore.publicKeyHex}`, {
                method: "GET"
            }).then(res => {
                let errorText: string;
                switch (res.status) {
                    case 200:
                        res.json().then(out => {
                            let iceServers = JSON.parse(out.iceServers);
                            rtcClient = new RTCClient();
                            rtcClient.start(keyStore, iceServers, out.peerKey, timeoutId);
                        });
                        break
                    case 404:
                    case 410:
                        errorText = "No valid subscription found. Please make sure you are signing in with the same account you used when purchasing your subscription. " +
                            "If you do not have a subscription please purchase one from inside the app.";
                        break;
                    case 418:
                        errorText = "Your subscription has expired, please buy a new subscription to continue using this service.";
                        break;
                    case 500:
                        errorText = "Server error, please try again.";
                        break;
                    default:
                        errorText = `Connection request failed wih status ${res.status}. ERROR ${res.statusText}!`
                        break;
                }
                if (errorText) {
                    document.querySelector("#loadingState").innerHTML = `${errorText}`;
                    connectionRequestBtn.innerHTML = "Request connection";
                }
            }).catch(err => {
                document.querySelector("#loadingState").innerHTML = `Connection request failed wih error ${err}.`;
                console.error(err);
                // logout(err, null);
            });
        });
        connectionRequestBtn.click();

    } else {
        if (user) {
            console.warn("no user ID defined, configuration error?")
        } else {
            console.warn("no user logged in!");
        }
        location.reload();
    }
}

export function disconnected() {
    document.querySelector("#loadingState").innerHTML = "Client disconnected";
    document.getElementById("request-connection").innerHTML = "Request connection"
    document.body.setAttribute("class", "login-image"); // show
    show("login-container");
    hide("map-container");
}

function logout(errorMessage: string, errorText: string) {
    console.warn(errorMessage, errorText);
    switch (errorMessage) {
        // known errors
        case "404":
        case "410":
        case "418":
        case "500":
            sessionStorage.setItem("logoutReason", errorMessage);
            break;
        default:
            // unknown errors
            sessionStorage.setItem("logoutReason", errorText ?? errorMessage); // [errorText ?? errorMessage] === [errorText !== null ? errorText : errorMessage]
            break;
    }
    rtcClient.stop();
    signOut(auth).then(() => {
        location.reload();
    });
}

function show(elementId: object|string) {
    if (typeof elementId === "object") {
        for (let i in elementId) {
            show(elementId[i]);
        }
    } else {
        document.querySelector(`#${elementId}`).removeAttribute("style");
    }
}

function hide(elementId: object|string) {
    if (typeof elementId === "object") {
        for (let i in elementId) {
            hide(elementId[i]);
        }
    } else {
        document.querySelector(`#${elementId}`).setAttribute("style", "display: none;");
    }
}

export function showMapPage() {
    hide("login-container");
    show("map-container");
    document.body.removeAttribute("class"); // remove background
}

document.addEventListener('DOMContentLoaded', () => {
    let isDebugMode = false;
    console.log("Debug mode: " + isDebugMode);
    if (isDebugMode) {
        setTimeout(() => {
            document.body.removeAttribute("class");
            showMapPage();
            let locationList = [[37.421998333333335, -122.0841],
                                [37.4221, -122.0842],
                                [37.422225871957046, -122.08393364135394]];
            let jsonData =
                {
                    "SYSTIME": new Date().getUTCMilliseconds(),
                    "ACCOUNT": null,
                    "ALARM_STATUS": true,
                    "SNOOZE_STATUS": false,
                    "INITIAL_ANCHOR_DISTANCE": 26.0,
                    "INITIAL_ANCHOR_BEARING": 13.09397,
                    "DRIFT_DISTANCE": 17.0,
                    "EXCLUSION_START": 230.0,
                    "EXCLUSION_ANGLE": 128.0,
                    "SAFE_ZONE_ACTIVE": true,
                    "BEARING_TO_ANCHOR": 13.09397,
                    "DISTANCE_TO_ANCHOR": 26.0001,
                    "MAG_DECLINATION": 13.040444,
                    "IN_SAFE_ZONE": true,
                    "VESSEL_SIZE": 9,
                    "BATTERY_LEVEL": 20,
                    "TRACKING_ONLY": true,
                    "METRIC": true,
                    "ANCHOR_LOCATION": {
                        "LATITUDE": 37.422225871957046,
                        "LONGITUDE": -122.08393364135394
                    },
                    "VESSEL_LOCATION": {
                        "LATITUDE": 37.421998333333335,
                        "LONGITUDE": -122.084
                    },
                    "LOCATION_HISTORY_LIST": locationList
                };
            populateData(jsonData);
            draw(jsonData);
            drawHistory(jsonData);
            setInterval(() => {
                let random = Math.round(Math.random() * 2);
                let location = locationList[random];
                jsonData.SYSTIME = new Date().getMilliseconds();
                jsonData.VESSEL_LOCATION.LATITUDE = location[0];
                jsonData.VESSEL_LOCATION.LONGITUDE = location[1];
                populateData(jsonData);
                drawVessel(jsonData);
            }, 1000);

            if (jsonData.IN_SAFE_ZONE) {
                cancelAlert();
            } else {
                showAlert();
            }

        }, 500);
    } else {

        hide(['map-container', 'loading-container', 'error-container']);
        show(['login-container', 'login-button']);
        document.querySelector("#login").addEventListener("click", async() => {
            sessionStorage.removeItem("logoutReason");
            hide(['error-container', 'logoutReason']);
            const googleAuthProvider = new GoogleAuthProvider();
            googleAuthProvider.setCustomParameters({
                prompt: 'select_account'
            });
            let result = await signInWithPopup(auth, googleAuthProvider);
            connectToPeer(result.user);
        });
        document.querySelector("#logout").addEventListener("click", () => signOut(auth).then(() => location.reload()));
    }
});
