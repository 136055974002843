import {Control} from "ol/control";
import {getAuth, signOut} from "firebase/auth";

export class LogoutControl extends Control {
    /**
     * @param {Object} [opt_options] Control options.
     */
    constructor(opt_options) {
        const options = opt_options || {};

        const button = document.createElement('button');
        button.innerHTML = 'Sign Out';
        button.className = 'button';

        const element = document.createElement('div');
        element.className = 'logout';
        element.appendChild(button);

        super({
            element: element,
            target: options.target,
        });

        button.addEventListener('click', this.handleLogout.bind(this), false);
    }

    handleLogout() {
        signOut(getAuth()).then(() => {
            location.reload();
        }).catch((error) => {
            // TODO: whaaaa???!!
            // An error happened.
        });
    }
}
