import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OSM, { ATTRIBUTION } from 'ol/source/OSM';
import Feature from 'ol/Feature';
import {Icon, Stroke, Style} from 'ol/style';
import {fromLonLat, getPointResolution} from 'ol/proj';
import {Control, defaults as defaultControls, ScaleLine} from 'ol/control';
import {Circle, LineString, Point} from 'ol/geom';
import {defaults as defaultInteractions} from 'ol/interaction/defaults';
import {LogoutControl} from "./LogoutControl";

let lonLat = [174.7489432305216, -36.83745299643887];

let zoom = 17;
let isCentred = false;

const openSeaMapLayer = new TileLayer({
    source: new OSM({
        attributions: [
            'All maps © <a href="https://www.openseamap.org/">OpenSeaMap</a>',
            ATTRIBUTION,
        ],
        opaque: false,
        url: 'https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png',
    }),
});
const map = new Map({
    controls: defaultControls({rotate: false}).extend([new LogoutControl(undefined)]),
    target: 'map-container',
    layers: [
        new TileLayer({
            source: new OSM(),
        }),
        openSeaMapLayer
    ],
    view: new View({
        center: fromLonLat(lonLat),
        zoom,
        enableRotation: false
    }),
    // disable rotation
    interactions: defaultInteractions({altShiftDragRotate: false, pinchRotate: false})
});

const audio = new Audio("/audio/buzzer.ogg");
audio.loop = true;

const alert = document.createElement('button');
alert.id = 'alert';
alert.className = 'alert';
alert.innerHTML = "VESSEL OUTSIDE SAFE ZONE!!<br/>CLICK TO CANCEL!"
alert.addEventListener('click', () => {
    cancelAlert();
});
let alertControl = new Control({element: alert});
let trackingOnly: boolean = false;
const info = document.createElement('span');
info.id = 'info';
info.className = 'info';
let infoControl = new Control({element: info});
map.addControl(infoControl);

map.addControl(new ScaleLine({
    bar: true,
    steps: 4,
    minWidth: 140,
}));

const circleSource = new VectorSource();
const circleLayer = new VectorLayer({
    source: circleSource,
});

const anchorSource = new VectorSource();
const anchorLayer = new VectorLayer({
    source: anchorSource,
});
anchorLayer.setZIndex(1);

const vesselSource = new VectorSource();
const vesselLayer = new VectorLayer({
    source: vesselSource,
});
vesselLayer.setZIndex(2);

const historySource = new VectorSource();
const historyLayer = new VectorLayer({
    source: historySource,
});
historyLayer.setZIndex(1);

map.addLayer(circleLayer);
map.addLayer(anchorLayer);
map.addLayer(vesselLayer);
map.addLayer(historyLayer);

const locate = document.createElement('div');
locate.className = 'ol-control ol-unselectable locate';
locate.innerHTML = '<button title="Locate Vessel">◎</button>';
locate.addEventListener('click', centre);
map.addControl(new Control({element: locate}));

const compass = document.createElement('div');
compass.className = 'compass';
compass.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" id="svg2233" viewBox="0 0 90 194" width="30" height="60">\n' +
    '    <g id="compasssvg" transform="translate(-252.92 -381.54)">\n' +
    '        <title>Magnetic North</title>' +
    '        <g id="g4343">\n' +
    '            <g id="g4335">\n' +
    '                <path id="path4331" style="stroke:#ffffff;stroke-width:3.5;fill:#000000"\n' +
    '                    d="m299.87 385.41-19.667 57.82 17.307-18.88v147.33h2.36v-186.27z" />\n' +
    '                <path id="path3648" style="fill:#000000"\n' +
    '                    d="m299.87 385.41-19.667 57.82 17.307-18.88v147.33h2.36v-186.27z" />\n' +
    '            </g>\n' +
    '            <g id="g4339">\n' +
    '                <path id="path4320" style="stroke:#ffffff;stroke-width:3.5;fill:#000000"\n' +
    '                    d="m256.8 523.18c2.5375 0.18797 3.8533 0.28195 5.169 0.28195 4.0412 0 6.5788-0.5639 9.0223-2.0676 4.6051-2.7255 9.3042-9.4922 12.312-17.387 1.5037-4.0412 2.0676-6.1089 6.1088-22.368l3.4774 14.567c2.7255 11.654 4.9811 18.515 8.1765 24.905 5.7329 11.654 14.003 17.387 25.093 17.387 4.7931 0 8.1765-0.84584 12.406-3.2894l-2.2556-8.3644c-1.3158 3.1014-1.9736 4.4172-3.1954 5.8269-1.6917 2.0676-4.0412 3.2894-6.3908 3.2894-5.9209 0-11.372-4.6052-16.165-13.721 1.5977-8.0825 2.4435-11.748 4.7931-21.616 4.0412-16.353 6.1088-23.12 9.1163-29.322 1.9736-3.9472 4.2292-5.451 8.5524-5.451 0.65788 0 1.2218 0 2.5375 0.18797l3.4774-6.2968c-1.1278-0.094-2.1616-0.18796-2.9134-0.18796-3.9473 0-8.1765 2.7255-13.064 8.4584-4.5112 5.263-6.2028 10.056-10.808 30.356-0.93982 4.4172-1.9736 8.8343-3.0074 13.345-0.094 0.46991-0.5639 2.4435-1.4097 5.357-4.5111-9.0223-5.7329-12.688-15.695-49.905l-3.1954 2.9134c-2.2556-0.18797-3.4774-0.28195-5.169-0.28195-5.6389 0-9.0223 1.0338-12.406 3.6653-6.0149 4.6991-10.15 11.466-10.15 16.635 0 2.3496 0.65788 4.7931 2.3496 8.2704l6.4848-4.4172c-2.3496-4.981-3.0074-7.1426-3.0074-9.4922 0-2.8195 1.5977-5.357 4.2292-6.7667 2.2556-1.2218 4.5112-1.5977 8.7404-1.5977 1.9736 0 3.6653 0 7.2366 0.18796-4.4172 18.89-7.9885 30.732-11.09 36.277-1.9736 3.4773-4.0412 4.5112-9.0223 4.5112-1.7857 0-3.1954-0.094-5.8269-0.37593l-4.5112 6.4848" />\n' +
    '                <path id="path4323" style="stroke:#ffffff;fill:#000000"\n' +
    '                    d="m256.8 523.18c2.5375 0.18797 3.8533 0.28195 5.169 0.28195 4.0412 0 6.5788-0.5639 9.0223-2.0676 4.6051-2.7255 9.3042-9.4922 12.312-17.387 1.5037-4.0412 2.0676-6.1089 6.1088-22.368l3.4774 14.567c2.7255 11.654 4.9811 18.515 8.1765 24.905 5.7329 11.654 14.003 17.387 25.093 17.387 4.7931 0 8.1765-0.84584 12.406-3.2894l-2.2556-8.3644c-1.3158 3.1014-1.9736 4.4172-3.1954 5.8269-1.6917 2.0676-4.0412 3.2894-6.3908 3.2894-5.9209 0-11.372-4.6052-16.165-13.721 1.5977-8.0825 2.4435-11.748 4.7931-21.616 4.0412-16.353 6.1088-23.12 9.1163-29.322 1.9736-3.9472 4.2292-5.451 8.5524-5.451 0.65788 0 1.2218 0 2.5375 0.18797l3.4774-6.2968c-1.1278-0.094-2.1616-0.18796-2.9134-0.18796-3.9473 0-8.1765 2.7255-13.064 8.4584-4.5112 5.263-6.2028 10.056-10.808 30.356-0.93982 4.4172-1.9736 8.8343-3.0074 13.345-0.094 0.46991-0.5639 2.4435-1.4097 5.357-4.5111-9.0223-5.7329-12.688-15.695-49.905l-3.1954 2.9134c-2.2556-0.18797-3.4774-0.28195-5.169-0.28195-5.6389 0-9.0223 1.0338-12.406 3.6653-6.0149 4.6991-10.15 11.466-10.15 16.635 0 2.3496 0.65788 4.7931 2.3496 8.2704l6.4848-4.4172c-2.3496-4.981-3.0074-7.1426-3.0074-9.4922 0-2.8195 1.5977-5.357 4.2292-6.7667 2.2556-1.2218 4.5112-1.5977 8.7404-1.5977 1.9736 0 3.6653 0 7.2366 0.18796-4.4172 18.89-7.9885 30.732-11.09 36.277-1.9736 3.4773-4.0412 4.5112-9.0223 4.5112-1.7857 0-3.1954-0.094-5.8269-0.37593l-4.5112 6.4848" />\n' +
    '            </g>\n' +
    '        </g>\n' +
    '    </g>\n' +
    '</svg>';

map.addControl(new Control({element: compass}));

export function centre() {
    if (trackingOnly && !vesselSource.isEmpty()) {
        let size = map.getSize();
        map.getView().centerOn(vesselSource.getExtent(), size, [size[0] /2, size[1] / 2]);
    } else if (!circleSource.isEmpty()) {
        map.getView().fit(circleSource.getExtent(), {
            maxZoom: 19,
            duration: 500,
        });
    }
}

export type Data = {
    LOCATION_HISTORY_LIST?: any;
    SYSTIME?: number;
    ACCOUNT?: any;
    ALARM_STATUS: boolean;
    SNOOZE_STATUS: boolean;
    INITIAL_ANCHOR_DISTANCE: number;
    INITIAL_ANCHOR_BEARING: number;
    DRIFT_DISTANCE: number;
    EXCLUSION_START: number;
    EXCLUSION_ANGLE: number;
    SAFE_ZONE_ACTIVE: boolean;
    BEARING_TO_ANCHOR: number;
    DISTANCE_TO_ANCHOR: number;
    MAG_DECLINATION: number;
    IN_SAFE_ZONE: boolean;
    VESSEL_SIZE: number;
    BATTERY_LEVEL: number;
    TRACKING_ONLY: boolean;
    METRIC: boolean;
    ANCHOR_LOCATION: {
        LATITUDE: number; LONGITUDE: number;
    };
    VESSEL_LOCATION?: {
        LATITUDE: number; LONGITUDE: number;
    };
}

export function drawHistory(dataJson: Data) {
    let locationHistoryList = dataJson.LOCATION_HISTORY_LIST;
    historySource.clear(true);
    // start at current vessel location and work our way back along the history
    let lonLatStart = fromLonLat([dataJson.VESSEL_LOCATION.LONGITUDE, dataJson.VESSEL_LOCATION.LATITUDE]);
    for (let i = 0; i < locationHistoryList.length; i++) {
        let lonLetEnd = fromLonLat([locationHistoryList[i][1], locationHistoryList[i][0]]);
        let lineString = new LineString([lonLatStart, lonLetEnd]);
        // draw history
        const line = new Feature(lineString);
        line.setStyle(new Style({
            stroke: new Stroke({
                color: '#ffffff',
                width: 1
            })
        }));
        historySource.addFeatures([line]);
        lonLatStart = lonLetEnd;
    }
}

export function draw(dataJson: Data) {
    if (trackingOnly) {
        circleSource.clear(true);
        anchorSource.clear(true);
    } else {
        lonLat = [dataJson.ANCHOR_LOCATION.LONGITUDE, dataJson.ANCHOR_LOCATION.LATITUDE];
        const initialRadius = dataJson.INITIAL_ANCHOR_DISTANCE;
        const driftRadius = dataJson.DRIFT_DISTANCE;
        const globalStyle = function (isInnerCircle) {
            return new Style({
                renderer(coordinates: any, state) {
                    const [[x, y], [x1, y1]]: any = coordinates;
                    const ctx = state.context;
                    const dx = x1 - x;
                    const dy = y1 - y;

                    const radius = Math.sqrt(dx * dx + dy * dy);
                    const initialBearing = rationaliseAngle(-90 + dataJson.INITIAL_ANCHOR_BEARING); // 90 subtracted due to coordinate system

                    const startAngle = dataJson.EXCLUSION_START === -1 ? 0 :
                        rationaliseAngle(initialBearing + dataJson.EXCLUSION_START) * (Math.PI / 180);
                    const endAngle = dataJson.EXCLUSION_START === -1 ? 0 :
                        rationaliseAngle(initialBearing + dataJson.EXCLUSION_START + dataJson.EXCLUSION_ANGLE) * (Math.PI / 180);

                    ctx.fillStyle = '#00959550';
                    ctx.strokeStyle = '#f90000AA';

                    // the actual drawing...
                    ctx.beginPath();
                    if (startAngle === endAngle || isInnerCircle && dataJson.SAFE_ZONE_ACTIVE) {
                        ctx.arc(x, y, radius, 0, Math.PI * 2);
                    } else {
                        ctx.moveTo(x, y);
                        ctx.arc(x, y, radius, endAngle, startAngle, false);
                        ctx.lineTo(x, y);
                    }
                    ctx.fill();
                    ctx.stroke();
                    // end drawing.........
                }
            });
        };

        // the point resolution at this position on this map projection
        let pointRes = getPointResolution(map.getView().getProjection(), 1, fromLonLat(lonLat));

        // total circle
        let outerCircle = new Circle(fromLonLat(lonLat), (initialRadius + driftRadius) / pointRes);
        let anchorCircle = new Feature({
            geometry: outerCircle
        });
        anchorCircle.setStyle(globalStyle(false));

        let innerCircle = new Circle(fromLonLat(lonLat), initialRadius / pointRes);
        let safeCircle = new Feature({
            geometry: innerCircle
        });
        safeCircle.setStyle(globalStyle(true));

        // anchor image
        const anchor = new Feature(new Point(fromLonLat(lonLat)));
        anchor.setStyle((feature, resolution) => {
            let anchorStyle = new Style({
                image: new Icon({
                    src: 'images/anchor.svg',
                })
            });
            let anchorImage = anchorStyle.getImage();
            anchorImage.setScale(1 / Math.pow(resolution, 1) / 150);
            return anchorStyle;
        });

        // draw anchor circle
        circleSource.clear(true);
        circleSource.addFeatures([anchorCircle, safeCircle]);

        // draw anchor
        anchorSource.clear(true);
        anchorSource.addFeatures([anchor]);
    }

    drawVessel(dataJson);

    // draw location history
    drawHistory(dataJson);

    // rotate magnetic north pointer
    document.getElementById("svg2233").setAttribute("transform", "rotate(" + dataJson.MAG_DECLINATION + ")");

    // centre everything - fire this only first time
    if (!isCentred) {
        centre();
        isCentred = true;
    }
    populateData(dataJson);
}

export function populateData(dataJson: Data) {
    let time = new Date();
    time.setUTCMilliseconds(dataJson.SYSTIME);
    trackingOnly = dataJson.TRACKING_ONLY;
    let batteryLevel = null;
    if (dataJson.BATTERY_LEVEL && dataJson.BATTERY_LEVEL > 0) { // ignore if missing or -1 as this means no battery level data available
        if (dataJson.BATTERY_LEVEL < 15) {
            batteryLevel = `Battery level: <span style='color: #ce1515'>${dataJson.BATTERY_LEVEL}%</span><br/>`
        } else {
            batteryLevel = `Battery level: ${dataJson.BATTERY_LEVEL}%<br/>`
        }
    }
    document.querySelector('#info').innerHTML =
       `Bearing to anchor: ${(dataJson.BEARING_TO_ANCHOR - dataJson.MAG_DECLINATION).toFixed(1)}&#176;<br/>
       Distance to anchor: ${dataJson.DISTANCE_TO_ANCHOR.toFixed(1)}${dataJson.METRIC ? 'm' : 'ft'}<br/>
       ${batteryLevel ?? ""}
       Last update: ${time.toLocaleTimeString()}<br/>`;
}

export function showAlert() {
    map.addControl(alertControl);
    audio.play();
}

export function cancelAlert() {
    // audio.loop = false;
    audio.pause();
    audio.currentTime = 0;
    map.removeControl(alertControl);
    // audio.removeEventListener('ended', alertLoopListener);
}

export function drawVessel(dataJson: Data) {
    // draw vessel
    const vessel = new Feature(new Point(fromLonLat([dataJson.VESSEL_LOCATION.LONGITUDE, dataJson.VESSEL_LOCATION.LATITUDE])));
    vessel.setStyle((feature, resolution) => {
        let vesselStyle = new Style({
            image: new Icon({
                src: dataJson.IN_SAFE_ZONE ? 'images/vessel-green.svg' : 'images/vessel-red.svg',
            })
        });

        let vesselImage = vesselStyle.getImage();
        vesselImage.setScale(1 / Math.pow(resolution, 1) / (9 / dataJson.VESSEL_SIZE)); // 9 = default vessel size
        vesselImage.setRotation((Math.PI / 180) * rationaliseAngle(dataJson.BEARING_TO_ANCHOR));
        return vesselStyle;
    });

    // draw vessel
    vesselSource.clear(true);
    vesselSource.addFeatures([vessel]);
}

function rationaliseAngle(angle: number) {
    while (angle < 0) {
        angle += 360;
    }
    while (angle > 360) {
        angle -= 360;
    }
    return angle;
}
